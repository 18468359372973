.GovAUWrapper {
    background: #18335c;
    height: 2.75rem;
    color: #fff;
    font-family: "Open sans", sans-serif;
}

.GovAUContent {
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    padding: 8px;
    align-items: center;
    font-family: "Open Sans",sans-serif;
}

.headerContent {
    max-width: 1140px;
    margin: 0 auto;
    font-family: "Open Sans",sans-serif;
}

.newRdtiLogo {
    height: auto;
    width: 328px;
    @media (max-width: 576px) {
        width: 253px;
    }
}

.mainBanner {
    background-color: #254f90;
    /*min-height: 76px;*/
    padding: 1.5rem;
    @media only screen and (min-width: 768px) {
        padding: 2rem;
    }
}
