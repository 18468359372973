@import '../../../globalstyles/variables';

.criticalError {
    margin-bottom:          2.5rem !important;
    border:                 2px solid $red !important;
    background:             $defaultColor;
    padding:                1rem;
}

.criticalError p {
    font-size:              1.125rem;
    font-weight:            bold;
    line-height:            1.22;
    padding:                0.5rem;
}

.criticalError li {
    font-size:              1rem;
    font-weight:            500;
    line-height:            1.25;
    color:                  $red;
    margin-bottom:          0.750rem;
    list-style:             none;
}
.criticalError li a{
    font-size:              1rem;
    font-weight:            500;
    line-height:            1.25;
    color:                  $red;
    margin-bottom:          0.750rem; 
    text-decoration:        underline;
}
.criticalError li p {
    font-size:              1rem;
    font-weight:            500;
    padding:                0;
    color:                  $red;
    margin-bottom:          0.5rem;
}

.criticalError ul {
    margin-bottom: 1rem;
    padding-left: 0.5rem;
}
