@import '../../globalstyles/variables';

.mobileNav {
    background-color: $navBackground;
    padding: 1.5rem;
    border-bottom: 1px solid $lighterGrey;
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
        border: none;
        padding: 0;
    }
}

.navHeader {
    background-color: white;
    height: 4.3125rem;
    width: 100%;
    border-bottom: 1px solid $lighterGrey;
    padding: 0.25rem 1rem 0.313rem;
    display: flex;
    align-items: center;
    
    @media (min-width: 768px) {
        display: none;
    }
}

.currentTitle {
    font-size: 1.125rem;
    font-weight: bold;
}

.nextTitle {
    font-size: 0.875rem;
    color: $matteText;
}

.hamburgerMenuOpen {
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    span {
        opacity: 0;
    }
    &:before {
        -webkit-transform: translateY(10px) rotate(135deg);
        -moz-transform: translateY(10px) rotate(135deg);
        transform: translateY(10px) rotate(135deg);
    }
    &:after {
        -webkit-transform: translateY(-10px) rotate(-135deg);
        -moz-transform: translateY(-10px) rotate(-135deg);
        transform: translateY(-10px) rotate(-135deg);
    }
}
