@import '../../globalstyles/variables';

.red {
    background: $red;
    color: $white;
    font-size: 13px;
}

.countCircle {
    border-radius: 1rem;
    -moz-border-radius: 1rem;
    -webkit-border-radius: 1rem;
    display: inline-block;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    width: 1.5rem;
}
