@import '../../globalstyles/variables';

.profileLink {
    background-color: $primary;
    text-decoration: none;
    border-width: 0px;
    padding: 0px;
    width: 100%;
    white-space: normal;
    font-size: 0.875rem;
    line-height: 1.2;
    justify-content: flex-end;
    &:focus {
        outline: 2px solid $focusColorOnDarkBackground;
        outline-offset: 2px;    
    }
}

.accountIcon {
    height: 2.375rem;
    width: 2.375rem;

    @media (min-width: 768px) {
        height: 3rem;
        width: 3rem;
    }
}

.accountIconWithContext {
    height: 2.375rem;
    width: 2.375rem;

    @media (min-width: 768px) {
        height: 3rem;
        width: 3rem;
    }
}

.accountDetailsWrapper {
    color: $defaultColor;
    display: none;

    @media (min-width: 768px) {
        display: inline;
    }
}

.accountDetailsWrapperInMenu {
    color: #333333;
    display: inline;

    @media (min-width: 768px) {
        display: none;
    }

    @media (max-width: 767px) {
        font-size: 0.75rem;
        padding: 1em;
    }
}

.headerDropDown button {
    padding-left: 0.75em;
}

.accountDetailsWrapperRow {
    overflow: hidden;
}

.accountDetailsSpacer {
    display: inline;

    @media (min-width: 768px) {
        display: none;
    }
}

.accountUserName {
    font-weight: bold;
}

.mobileMenuDetails {
    @media (min-width: 768px) {
        display: none;
    }
    @media (max-width: 767px) {

    }
}
