@import 'variables';

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  color: $matteText;
  line-height: inherit;

  &:focus {
    box-shadow: none;
    outline: 2px solid $focusColor;
    outline-offset: 2px;
  }

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 3.5rem;
  }
}

.rdtiH2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $headerTextColor;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
  }
}

.rdtiH3,
h3 {
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $headerTextColor;
  margin-bottom: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: 1.25rem;
  }
}

.rdtiH4 {
  font-size: 1rem;
  font-weight: 650;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: $matteText;
  margin-bottom: 1.5rem;
  @include media-breakpoint-up(md) {
    font-size: 1rem;
  }
}

.globalContentText {
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: $text;
  margin-bottom: 2.5rem;

  p,
  ul,
  blockquote,
  span {
    margin-bottom: 1rem;
    line-height: 1.5;
  }

  h2,
  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $headerTextColor;
    margin-bottom: 1.5rem;
    @include media-breakpoint-up(md) {
      font-size: 1.5rem;
    }
  }

  a {
    color: $link;
    text-decoration: underline;
    &:hover {
      color: $linkHover;
      text-decoration: underline;
    }
    &:visited {
      color: $linkVisited;
    }
  }
}

.globalContentText a {
  color: $link;
  text-decoration: underline;
  &:hover {
    color: $linkHover;
    text-decoration: underline;
  }
  &:visited {
    color: $linkVisited;
  }
}

.hintText {
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.375;
  letter-spacing: normal;
  text-align: left;
  color: $inlineText;

  p,
  ul,
  blockquote,
  span {
    margin-bottom: 0.75rem;
  }

  a {
    color: $link;
    text-decoration: underline;
    &:hover {
      color: $linkHover;
    }
    &:visited {
      color: $linkVisited;
    }
  }
}

b,
strong {
  font-weight: 600;
}

.helpTextLink {
  color: $link !important;
  text-decoration: underline;
  &:hover {
    color: $linkHover !important;
  }
  &:visited {
    color: $linkVisited;
  }
}
