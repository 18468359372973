@import '../../globalstyles/variables';

.envBox {
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 5px;
    left: 44%;
    font-size: smaller;
}

.envLocal {
    background-color: $backgroundColor;
    color: $tealPrimary;
}

.envDev {
    background-color: $backgroundColor;
    color: $magentaPrimary;
}

.envTest {
    background-color: $backgroundColor;
    color: $orangePrimary;
}

.envPreProd {
    background-color: $backgroundColor;
    color: $purplePrimary;
}

.envUAT {
    background-color: $backgroundColor;
    color: $slatePrimary;
}
