// Override Bootstrap global styles for modal windows.

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: none;
    padding: 2rem;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 0 2rem;
    line-height: 1.38;
    letter-spacing: normal;
}

.modal-content {
    border-radius: 0;
    background-color: #fafafa;
}

.modal-footer {
    justify-content: flex-end;
    padding: 2rem;
    border-top: none;
    display: inline-flex;
}

@media (min-width: 992px) {
    .modal-md {
        max-width: 700px;
    }
}
