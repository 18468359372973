@import '../../globalstyles/variables';

.buttonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 1.5rem 0 0 0;
    margin-bottom: 4rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 576px) {
        display: block;

        .primaryButton {
            float: right;
            margin: 8px 0px 0px 0px;
        }
    }
}