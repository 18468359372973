@import '../../globalstyles/variables';

.companyDetails {
    margin-bottom: 2.5rem;
    table {
        max-width: 200px;
        td, th {
            padding: 0;
        }
    }
}

.heading {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: $matteText;
    @media only screen and (min-width: 768px) {
        font-size: 2.250rem;
        font-weight: 500;
    }
}

.subHeaderData, .subHeaderTitle {
    line-height: 1.375rem;
    color: $text;
    padding-right: 0.5rem;
}

.subHeaderData {
    font-weight: 400;
}

.subHeaderTitle {
    font-weight: 600;
}
