// ------------ COLOURS ------------- //

// --- Brand Colours --- //

// BGA Primary
$primary:                           #254F90;
$primaryInteractive:                #4569A0;
$primaryTint1:                      #B3C2D8;
$primaryTint2:                      #EFF4FB;
$primaryHover:                      #1F4176;
$primaryActive:                     #18335C;

// BGA Secondary
$secondary:                         #E5B13D;
$secondaryInteractive:              #EDCA72;
$secondaryTint1:                    #F3DEA7;
$secondaryTint2:                    #FCF4E3;

// -- Neutrals -- //

// Greys
$darkestGrey:                       #505050;
$darkerGrey:                        #737373;
$darkGrey:                          #A0A0A0;
$contrastGrey:                      #919191;
$grey:                              #C4C4C4;
$lightGrey:                         #C7C7C7;
$lighterGrey:                       #D8D8D8;
$lightestGrey:                      #EBEBEB;
$backgroundGrey:                    #FAFAFA;

// --- Backgrounds --- //
$defaultColor:                      #FFFFFF;
$backgroundColor:                    $backgroundGrey;
$inputPrependGrey:                  #E9ECEF;
$navBackground:                     #EDF0F4;

// --- MyGov --- //
$myGovDarkGreen:                    #143B3E;

// --- Landing page history --- //
$groupHeaderBackground:              #E7EAF0;
$columnHeaderBackground:             #F4F6F8;
$rowBorder:                          #D8DCE0;

// Wizard
$wizardBackgroundGrey:              #EFF3F7;

// --- Tables --- //

$tableHeaderBorderBottom:           #BFC6CC;
$tableDetailRow:                    #EFF3f7;
$tableHeaderBackgroundColor:        #d8dce0;
$tableTotalRowBackgroundColor:      #fcf4e3;

// --- Alerts --- //

// Good
$green:                             #368635;
$greenInteractive:                  #219520;
$greenTint1:                        #A6D5A6;
$greenTint2:                        #E8F4E8;

// Bad
$red:                               #D7201D;
$redInteractive:                    #e75755;
$redTint1:                          #F5B5B5;
$redTint2:                          #FCE9E9;
$tomato:                            #E22C2A;
$darkRed:                           #AF1A18;

// Warning
$warning:                           #FF7600;
$warningLite:                       #FEB11B;

// Information
$info:                              #3E96FF;

// --- Navigation --- //
$navigationInProgress:              #672F8D;

// --- Focus states --- //
$focusColor:                        #C861FF;
$focusColorOnDarkBackground:        #C390F9;
$focusShadowColor:                  rgba(0, 123, 255, .25);

// --- Text --- //
$headerTextColor:                   #333333;
$text:                              #333333;
$matteText:                         #474747;
$greyText:                          #737373;
$inlineText:                        #606060;
$helpText:                          #606060;
$link:                              #0063E8;
$linkHover:                         #1F4176;
$linkHoverBackground:               #DDEEFF;
$linkHoverBackgroundLight:          #F8FBFF;
$linkVisited:                       #0063E8;
$disabledText:                      #606060;

// --- Buttons --- //
$primaryButtonTextColor:             $defaultColor;
$buttonGroupBorder:                 #d8dce0;
$primaryButtonBackgroundColor:       $primaryInteractive;
$primaryButtonBorderColor:           $primary;
$primaryButtonBorderHover:           $primaryActive;

$tertiaryBtnUnderline:              #748FB8;

$secondaryButtonTextColor:          $primaryInteractive;
$secondaryButtonBorderColor:        $primary;
$secondaryButtonBackgroundColor:    $defaultColor;

$defaultButtonColor:                $primaryInteractive;
$defaultButtonBackgroundColor:      $defaultColor;
$defaultButtonBorderColor:          $primary;

$buttonFontSize: 1rem;
$buttonFontWeight: 700;
$buttonLetterSpacing: 0.01875rem;

// questions
$questionFontSize: 1rem;
$questionFontWeight: 600;
$questionFontStyle: normal;
$questionLineHeight: 1.5;
$questionMarginBottom: 0.75rem;
$label: $text;

// Checkbox
$checkboxLabelFontSize: 1rem;
$checkboxLabelInlineFontSize: 0.875rem;
$checkboxLabelFontWeight: 500;
$checkboxBackgroundColor: $defaultColor;
$checkboxUncheckedBorderColor: $contrastGrey;
$checkboxCheckedBorderColor: $primary;

// Radiobutton
$radiobuttonLabelFontSize:         $questionFontSize;
$radiobuttonLabelFontWeight:       500;
$radiobuttonBackgroundColor:       $defaultColor;
$radiobuttonUncheckedBorderColor:  $contrastGrey;
$radiobuttonCheckedBorderColor:    $primary;
$radiobuttonLabelColor:            $text;
$radiobuttonHover:                 $primaryTint2;

// other
$textColor:                         $text;
$inputElementBorderColor:           $contrastGrey;
$disabledBorderColor:               #c7c7c7;
$disabledBackgroundColor:          rgba(199, 199, 199, 0.3);
$disabledLabelColor:                $darkerGrey;

$grid-gutter-width: 16px !default;

$tileBorderColor:                   #d9d9d9;

$inactiveProgressIndicatorTextColor: $darkerGrey;

// --- Category Colours --- //

// Teal
$tealPrimary:                       #207E7E;
$tealInteractive:                   #54B3B3;
$tealTint1:                         #A9D9D9;
$tealTint2:                         #DFF1F1;

// Blue
$bluePrimary:                       #3E96FF;
$blueInteractive:                   #5BA6FF;
$blueTint1:                         #BEDCFF;
$blueTint2:                         #EBF4FF;
$blueActive:                        #EFF6FC;
$hoverBackground:                   #F4F8FD;
$hoverBackgroundBorder:             #E0E8F2;

// Orange
$orangePrimary:                     #C24E00;
$orangeInteractive:                 #FF6F4F;
$orangeTint1:                       #FFB7A7;
$orangeTint2:                       #FFE4DE;

// Slate
$slatePrimary:                      #293F5C;
$slateInteractive:                  #495C74;
$slateTint1:                        #AAB2C3;
$slateTint2:                        #DFE2EE;

// Purple
$purplePrimary:                     #5B43D0;
$purpleInteractive:                 #735FD7;
$purpleTint1:                       #BDB4EC;
$purpleTint2:                       #F1EEFA;

// Magenta
$magentaPrimary:                    #A42079;
$magentaInteractive:                #B64D94;
$magentaTint1:                      #DFB1D0;
$magentaTint2:                      #FFECF3;

//White
$white:                             #FFFFFF;
$whiteSmoke:                        #F5F5F5;
