@import '../../globalstyles/variables';

.navSubHeaderTitle {
    font-size: 0.875rem;
    font-weight: bold;
    padding-right: 0.5rem;
    white-space: nowrap;
}

.navSubHeaderData {
    font-size: 0.875rem;
    font-weight: 500;
    word-break: break-word;
    white-space: normal;
}

.navSubHeader {
    background-color: $navBackground;
    color: $matteText;
    border-bottom: 0px solid $lighterGrey;
    margin-bottom: 1rem;

    table {
        color: $matteText;
        th, td {
            padding: 2px 0;
            line-height: 1.250rem;
        }
        th {
            white-space: nowrap;
        }
    }
    @media (min-width: 768px) {
        border: none;
        min-width: 240px;
        max-width: 373px;
        table {
            th, td {
                padding: 2px 16px 2px 0px;
            }
        }
    }
}

.navFormHeader {
    margin-bottom: 1.5rem;
}

.navRequestHeader {
    margin-top: 2rem;
}

.formName {
    font-size: 1rem;
    line-height: 1.375rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
}

.formNumber {
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 0;
}

.navSubHeaderError {
    font-size: 0.875rem;
    color: $darkRed;
    font-weight: bold;
}
