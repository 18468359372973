@import '../../globalstyles/variables';

.signInPageWrapper {
    margin: 0 auto;
    max-width: 1140px;
    padding: 3.5rem 0;
}

.heading {
    font-family: "Open Sans", sans-serif;
    font-size: 44px;
    line-height: 1.27;
    font-weight: 600;
    color: $headerTextColor;
    margin-bottom: 56px;
    letter-spacing: 0;

    @media (max-width: 576px) {
        font-size: 32px;
        line-height: 36px;
        margin-bottom: 3rem;
    }
}

.subHeading {
    font-family: "Open Sans", sans-serif;
    font-size: 32px;
    line-height: 1.25;
    font-weight: 600;
    color: $headerTextColor;
    margin-bottom: 2rem;
    letter-spacing: 0;

    @media (max-width: 576px) {
        font-size: 1.5rem;
        line-height: 30px;
    }
}

.calloutHeading {
    font-family: "Open Sans", sans-serif;
    font-size: 21px;
    line-height: 28px;
    font-weight: 600;
    color: $headerTextColor;
    margin-bottom: 2rem;
    letter-spacing: normal;

    @media (max-width: 576px) {
        line-height: 30px;
    }
}

.calloutBox {
    font-family: "Open Sans", sans-serif;
    background: $lightestGrey;
    height: auto;
    border-top: 4px solid $lighterGrey;
    display: block;
    padding: 2rem;
    letter-spacing: 0.0125rem;
    line-height: 1.78;

    @media (max-width: 576px) {
        padding: 1.5rem;
    }

    ul {
        font-family: "Open Sans", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.78;
        letter-spacing: normal;
        text-align: left;
        list-style: none;
        text-indent: -34px;
        padding-left: 34px;
        margin-bottom: 2rem;

        li {
            font-family: "Open Sans", sans-serif;
            padding-bottom: 1rem;
            line-height: 32px;

            svg {
                width: 18px;
                height: 15px;
                margin-right: 1rem;
                object-fit: contain;
            }
        }
    }
}

.signInPageContent {
    margin-bottom: 1.875rem;

    p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 16px;
        letter-spacing: 0.2px;
        line-height: 28px;
    }

    a {
        color: #254f90 !important;
        background-color: transparent;
        border: 1px solid transparent;
        border-bottom: #748fb8 solid 2px;
        box-shadow: none;
        cursor: pointer;
        display: inline;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        height: auto;
        letter-spacing: 0.2px;
        line-height: 28px;
        transition-delay: 0s, 0s;
        transition-duration: 0.25s, 0.25s;
        transition-property: box-shadow, -webkit-box-shadow;
        transition-timing-function: ease, ease;
        text-decoration: none;
        box-sizing: border-box;

        //--- Add external link icon to any hyperlink that has opens in new tab ---//
        &[target='_blank']::after {
            content: url('/images/icon-externalLink.svg');
            display: inline-block;
            margin-left: 4px;
            height: 12px;
            width: 12px;
            position: relative;
            top: 1px;
        }

        &:visited {
            color: #a42079 !important;
            border-bottom-color: #dfb1d0;
            fill: #a42079;
        }

        &:hover {
            background-color: transparent;
            border-bottom: none;
            box-shadow: #ddeeff 0px -20px 0px 0px inset;
            text-decoration: none;
        }

        &:focus {
            border: 1px dotted #254f90;
            border-bottom: #748fb8 solid 2px;
            outline: none;
        }

        &:active {
            color: #000000 !important;
            border-bottom: #748fb8 solid 2px;
            box-shadow: none;
        }
    }
}

.digitalidentitycontainer {
    max-width: 430px;
    border: 2px solid #979797;
    border-radius: 6px;
    background-color: #EEEEEE;
}

.digitalidentitybutton {
    text-align: center;
}

.digitalidentitybutton a {
    display: inline-block;
    border: none;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
    color: #ffffff;
    background-color: #000;
    margin: 15px;
    padding: 12.5px 25px;
}

.digitalidentitybutton a:hover,
.digitalidentitybutton a:focus {
    background-color: #333;
}

.digitalidentitybutton img {
    width: 55px;
    vertical-align: middle;
    padding-right: 7.5px;
}

.digitalidentitycontent {
    text-align: center;
    background-color: #EEEEEE;
    border-radius: 0 0 5px 5px;
    border-top: 2px solid #979797;
    line-height: 1em;
}

.digitalidentitycontent p {
    margin: 0;
    padding: 25px;
    color: #313131;
}
