.itemActionButton {
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: 768px) {
        width: initial;
        margin-bottom: 0;
    }
}

.cancelActionButton {
    @media (min-width: 768px) {
        margin-right: 41px;
    }
}

.nonDangerActionButton {
    @media (min-width: 768px) {
        float: right;
    }
}