@import '../../../globalstyles/variables';

.formGroup {
    margin-bottom: 2.5rem;
}

.formGroupError {
    margin-bottom: 2.5rem;
    padding-left: 0.5rem;
    border-left: 0.25rem solid $red;
}

.formGroupAlert {
    margin-top: 2rem;
}

.feedback {
    font-size: 0.875rem;
    font-weight: 500;
}

.alertLinkButton {
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

.alertLinkButton:hover {
    background-color: white;
    box-shadow: none;
    cursor: pointer;
}

@media (min-width: 576px) {
    .formGroup {
        margin-bottom: 3rem;
    }
    
    .formGroupError {
        margin-bottom: 3rem;
    }
}
