@import '../../globalstyles/variables';


.footerContainer {
    background-color: $primaryActive;
    width: 100%;
    padding: 0;
    flex-shrink: 0;
    color: #FFFFFF;
    font-family: "Open Sans",sans-serif;
}

.footerCustom {
    @media (min-width: 576px) and (max-width: 992px) {
        max-width: 100%;
    }
}

.footerTop {
    font-family: "Open Sans",sans-serif;
    padding: 4rem 1rem 2rem;
    img {
        max-width: 21rem;
        margin-bottom: 2rem;
    }
    a {
        &:focus {
            outline: 2px solid $focusColorOnDarkBackground;
            outline-offset: 2px;
        }
    }
}

.footerBottom {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.footerMini {
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.acknowledgement {
    font-family: "Open Sans",sans-serif;
    p {
        padding-bottom: 0;
        line-height: 2rem;
        margin-bottom: 2rem;
    }
}

.footerButton {
    color: white !important;
    font-family: "Open Sans",sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        color: white !important;
        font-weight: 400;
        text-decoration: underline;
    }
}

.footerLinks {
    font-family: "Open Sans",sans-serif;
    color: white !important;
    @media (max-width: 768px) {
        flex-direction: column;
        flex: 1 0 auto;
        max-width: 100%;
    }
    ul {
        margin-bottom: 0;
        padding: 0;
    }
    li {
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0 1.5rem 0 0;
        line-height: 1.5rem;
        color: #FFFFFF;
        @media (max-width: 768px) {
            display: block;
            padding: 0 0 1rem;
        }
        a {
            color: #FFFFFF !important;
            font-family: "Open Sans",sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-decoration: underline;
            cursor: pointer;
            &:hover {
                color: white !important;
                font-weight: 400;
                text-decoration: underline;
            }
        }
        a[target='_blank']::after {
            content: url('/images/icon-externalLinkFooter.svg');
            display: inline-block;
            margin-left: 4px;
            height: 12px;
            width: 12px;
            position: relative;
            top: 1px;
        }

    }
}

.footerPhone {
    text-align: right;
    margin-bottom: 0;
    display: flex;
    @media (max-width: 768px) {
        flex-direction: column;
        flex: 1 0 auto;
        max-width: 100%;
        text-align: left;
    }
    a {
        color: white !important;
        font-family: "Open Sans",sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 3rem;
        text-decoration: none;
        white-space: nowrap;
    }
}
