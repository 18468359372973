@import 'variables';


// Button styles
button {
    &:focus {
        outline-offset: 2px;
        outline: 2px solid $focusColor;
    }
} 

// Bootstrap Button overrides
.btn {
    font-weight: $buttonFontWeight;
    letter-spacing: $buttonLetterSpacing;
    line-height: 1.2;
    &:focus {
        outline: 2px solid $focusColor;
        outline-offset: 2px;
    }
}

.btn-primary, .btn-secondary {
    font-size: $buttonFontSize;
    border-radius: 0.25rem;
    border: solid 2px $primary;
    padding: 1rem 1.5rem;
    &:hover, &:focus {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
        border-color: $primary;
        background-color: $primary;
        color: $defaultColor;
    }
    &:focus {
        outline: 2px solid $focusColor;
        outline-offset: 2px;
    }
    &:active {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
        border-color: $primaryButtonBorderHover;
        background-color: $primaryButtonBorderHover;
        color: $defaultColor;
    }
    &.disabled {
        background-color: $disabledBackgroundColor;
        border-color: $disabledBorderColor;
        color: $disabledLabelColor;
        cursor: not-allowed;
    }
}
.btn-primary {
    background-color: $primaryInteractive;
    color: $defaultColor;
}
.btn-secondary {
    background-color: $defaultColor;
    color: $primaryInteractive;
}
.btn-link {
    font-size: 15px;
    font-weight: bold;
    color: $primaryInteractive;
    text-decoration: underline;
    padding: 0;
    &:hover {
        color: $primaryHover;
        text-decoration: none;
    }
    &:focus {
        outline: 2px solid $focusColor;
        outline-offset: 2px;
    }
    &:active {
        color: $primaryActive;
        text-decoration: none;
    }
    &.fileUploadFileName {
        font-size: 0.750rem;
        color: $greyText;
        font-weight: normal;
        &:focus {
            outline: 2px solid $focusColor;
            outline-offset: -2px;
        }
    }
}
.btn-delete {
    color: $red;
    &:hover {
        color: $darkRed;
    }
}

// Required to override Bootstrap - look for a better way to override these in future.
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle  {
    color: $defaultColor;
    background-color: $primaryButtonBorderHover;
    border-color: $primaryButtonBorderHover;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
}

// Required to override Bootstrap - look for a better way to override these in future.
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active + .deleteButton {
    color: $defaultColor;
    background-color: $red;
    border-color: $red;
}

// Width 100% for mobile screens only
.w-sm-100 {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: auto;
    }
}

.btn.disabled, .btn:disabled  {
    cursor: not-allowed;
    opacity: 0.5;
}

.btn-info {
    &:focus {
        outline: 2px solid $focusColor;
        outline-offset: 2px;
        box-shadow: none;
    }
    &:active {
        box-shadow: none;
        outline: 2px solid $focusColor;
        outline-offset: 2px;
    }
}

.btn-info:not(:disabled):not(.disabled):active, 
.btn-info:not(:disabled):not(.disabled).active, 
.show > .btn-info.dropdown-toggle {
    color: $primaryActive;
    background-color: transparent;
    border-color: $primaryActive;
}

.btn-info:not(:disabled):not(.disabled):active:focus, 
.btn-info:not(:disabled):not(.disabled).active:focus, 
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: none;
}

/* Dropdown Menu and dropdown button */

.dropdown-menu {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    border: none;
}
.dropdown-item {
    color: $primaryInteractive;
    &:hover, &:focus {
        color: $defaultColor;
        background-color: $link;
    }
}
