@import '../../../globalstyles/variables';

.pill {
    border-radius: 20px;
    text-align: center;
}

.projectPill {
    background-color: #eaf4ea;
}

.corePill {
    background-color: #eaeff8;
}

.supportingPill {
    background-color: #f7e5f4;
}

.pillInGrid {
    font-size: 0.688rem;
    font-weight: 700;
    padding: 2px 5px;
    height: 20px;
    max-width: 100px;
}
