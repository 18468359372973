@import '../../../globalstyles/variables';

.issuesColumn {
    height: 100%;
    display: table;
}

.issuesStyle {
    display: table-cell;
    vertical-align: middle;
    min-width: 23px;
    line-height: 1.875rem;
}

.chevronButton {
    width: 100%;
    height: 100%;
    display: table-cell;

    button {
        border: none;
        background: url('/images/icon-chevrondown-2.svg');
        background-repeat: no-repeat;
        background-size: 16.8px 10px;
        background-position: center;
        width: 100%;
        height: 100%;
        transition: transform .4s;
        min-width: 57px;
    }
}

.greenCheck {
    display: inline;

    svg {
        fill: #219520;
        height: 1.3rem;
        width: 1.3rem;
    }
}
