@import '../../../globalstyles/variables';

.adaptiveTable {
    border: 0;
    background: $white;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    line-height: 1.375;

    thead {
        display: none;

        @media (min-width: 768px) {
            display: table-header-group;
            border-bottom: $grey solid 1px;

            tr {
                background: $backgroundGrey;
            }

            th {
                border: 0;
                font-size: 0.75rem;
                font-weight: bold;
                color: $greyText;

                &:nth-child(3) {
                    width: 80px;
                }

                &:nth-child(4) {
                    width: 80px;
                }

                &:first-child {
                    padding-left: 1rem;
                    width: 85px;
                }

                &:last-child {
                    width: 92px;
                }
            }
        }
    }

    tbody {
        @media (min-width: 768px) {
            line-height: 1.375;
        }

        tr {
            display: block;
            border: 0;
            border-bottom: $lighterGrey solid 1px;
            background: $white;

            @media (min-width: 768px) {
                display: table-row;
            }
        }

        td {
            display: block;
            border: 0;
            font-size: 0.875rem;
            color: $headerTextColor;
            background: linear-gradient(90deg, $backgroundGrey 26%, $white 26%);
            padding: 1rem 1rem 0.25rem calc(26% + 1rem);
            position: relative;
            min-height: 54px;
            height: 54px;
            word-wrap: break-word;
            word-break: break-word;
            white-space: normal;

            @media (min-width: 768px) {
                display: table-cell;
                background: none;
                padding: 0.75rem;
                vertical-align: middle;
            }

            &:before {
                content: attr(data-label);
                float: left;
                font-size: 0.75rem;
                font-weight: bold;
                color: $greyText;
                display: table-cell;
                height: 100%;
                margin: auto;
                position: absolute;
                vertical-align: middle;
                white-space: nowrap;
                left: 1rem;

                @media (min-width: 768px) {
                    display: none;
                }
            }

            &:nth-child(2) {
                font-weight: 600;
            }

            &:first-child {
                padding-left: calc(26% + 1rem);

                @media (min-width: 768px) {
                    padding-left: 1rem;
                }
            }

            &:last-child {
                border-bottom: 1px solid $lighterGrey;
                padding: 1rem 1rem 1rem calc(26% + 1rem);

                @media (min-width: 768px) {
                    padding: 0;
                    padding-left: 0.75rem;
                    border-bottom: none;
                    height: 100%;
                    display: table-cell;
                }
            }
        }
    }
}

.chevronOpen {
    td {
        button {
            transform: rotate(180deg);
            transition: transform .4s;
        }
    }
}

.expandedRowStyle {
    background: $wizardBackgroundGrey !important;

    &:before {
        content: none !important;
        ;
    }

    &:after {
        content: none !important;
    }

    &:last-child {
        border-bottom: 1px solid $lighterGrey !important;
        padding: 1rem !important;
        max-width: 100% !important;
        position: initial !important;
        width: 100% !important;
        height: 100% !important;

        @media (min-width: 768px) {
            border-bottom: none !important;
        }
    }
}

.addButtonStyle {
    margin-bottom: 8px;
    width: 100%;

    @media (min-width: 768px) {
        margin-bottom: 0;
    }
}

.rowStyle {
    cursor: pointer;
}
