@import '../../../globalstyles/variables';

table caption {
    caption-side: top;
    margin-bottom: 0 !important;
    color: $text;
}

@for $i from 1 through 100 {
    $size: 1% * $i;
    .flexBasis#{$i} { 
        width: $size;
        }
}
.dataTableHeaders {
    background: $columnHeaderBackground;
    border-bottom: $rowBorder solid 1px;
    padding: 4px;
    margin-bottom: 0px;
    font-size: 0.750rem;
    font-weight: bold;
    color: $matteText;
    padding: 0;
}

.actionsColumnHeader {
    text-align: center;
}

.adaptiveTable {
    border: 0;
    background: transparent;
    table-layout: fixed;
    width: 100%;
    white-space: nowrap;
    height: 100%;
    line-height: 1.375;
    margin-bottom: 0px;

    thead {
        display: none;

        @media (min-width: 992px) {
            display: table-header-group;
            border-bottom: $grey solid 1px;

            tr {
                background: $backgroundGrey;
            }

            th {
                cursor: pointer;
            }
        }

    }

    tbody {
        @media (min-width: 992px) {
            line-height: 1.375;
        }

        tr {
            display: block;
            background: $white;
            border-bottom: 1px solid $lighterGrey;

            @media (min-width: 992px) {
                display: table-row;
            }

            &::last-child {
                padding: 1rem 1rem 1rem calc(26% + 1rem);
            }
        }

        td {
            display: flex;
            font-size: 0.875rem;
            color: $headerTextColor;
            background: linear-gradient(90deg, #F2F2F2 26%, $white 26%);
            border: none;
            align-items: center;
            padding: 0.75rem;
            vertical-align: middle;

            @media (min-width: 992px) {
                display: table-cell;
                background: none;
                vertical-align: middle;
            }

            &:before {
                content: attr(data-label);
                float: none;
                font-size: 0.75rem;
                font-weight: bold;
                color: $greyText;
                display: block;
                width: 30%;

                @media (min-width: 992px) {
                    display: none;
                }
            }
        }
    }
}

.hiddenTableHeader {
    thead {
        display: none;
    }
}

.hiddenTableData {
    width: 3%;
    border-top: 0 !important;
}


.rowStyle {
    cursor: pointer;
}

.editModal {
    min-height: 75vh;
}

.modalHeader {
    h1 {
        font-size: 2rem;
    }
}

.modalFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    border-top: 2px solid $lighterGrey;
    padding-top: 2rem;

    @media (min-width: 992px) {
        flex-direction: row;
    }
}

.headingLine {
    border-bottom: solid #edca72 0.1875rem;
    margin-bottom: 1rem;
    padding-bottom: 0.65625rem;
}

.editModalButton {
    width: 100%;
    margin-bottom: 2rem;

    @media (min-width: 992px) {
        width: initial;
        margin-bottom: 0;
    }
}

.cancelModalButton {
    @media (min-width: 992px) {
        margin-right: 2.625rem;
    }
}

.nonDangerModalButton {
    @media (min-width: 992px) {
        float: right;
    }
}

.pAndAButtonGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    @media (min-width: 768px) {
        display: inline-flex;
        flex-direction: inherit;
        margin-bottom: 2rem;
    }
}

.hiddenHeader {
    thead {
        opacity: 0;
        border: 0 none;
        height: 0;
    }

    thead * {
        margin: 0;
        padding: 0;
        border: 0 none;
        height: 0px;
    }
}

.chevronOpen {
    path {
        fill: grey !important;
    }

    transform: rotate(-90deg);
    transition: transform .4s;
}

.chevronClose {
    path {
        fill: grey !important;
    }

    transition: transform .4s;
}
