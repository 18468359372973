@import '../../globalstyles/variables';

.message {
    padding: 30px 30px 33px;
    border: solid 2px #d8dce0;
    background-color: white;
    margin-top: 2em;
    margin-bottom: 2em;
}

.message ul {
    padding-left: 0;
    list-style-type: none;
}

.message li {
    margin-bottom: 10px;
}

.message li svg {
    margin-right: 15px;
}

.buttonContainer {
    margin-top: 1em;
}