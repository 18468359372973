@import '../../../globalstyles/variables';

.btnStyle {
    width: 70%;
    white-space: initial;
    text-align: left;
    text-decoration: none !important;
    @media only screen and (min-width: 992px) {
        width: 100%;
    }
}

.linkText {
    font-weight: 600 !important;
    font-size: 0.875rem !important;
    margin: 0;
    text-decoration: underline;
    overflow: hidden;
    text-overflow: ellipsis;
}

.inlineError {
    font-size: 0.688rem;
    font-weight: bold;
    color: $red;
}
