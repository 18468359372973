.main-container {
  padding: 2.5rem 0;
  // > .container:last-child, >.container-fluid:last-child {
  //   padding-bottom: 6rem;
  // }

  @media (min-width: 768px) {
    flex: 1;
    // max-width: calc(100vw - 373px);
  }
}

.layout-nav-side {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .layout-nav-side {
    background-color: $navBackground;
    display: flex;
    display: -ms-flexbox;
    padding: 1rem;

    >.navbar {
      color: white;
      padding: 0;
      min-width: 240px;
      max-width: 373px;
      // height: 100%;
      // overflow-y: scroll;
      // -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      flex-direction: column;
      align-items: flex-start;

      .navbar-brand {
        margin-bottom: 3rem;
      }

      .navbar-collapse {
        align-items: flex-start;
        width: 100%;
        flex-shrink: 0;
        padding-bottom: 1.5rem;
      }

      .navbar-nav {
        flex-direction: column;
        width: 100%;
      }
    }

    .main-container {
      overflow: hidden;
      flex: 1;
      max-width: calc(100vw - 373px);

      >.container {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }

    // .content-container {
    //   display: flex;
    //   height: calc(100vh - 3.5625rem);
    // }
  }

  .layout-sidebar {
    .main-container {
      display: flex;
      flex-direction: row-reverse;
      height: calc(100vh - 59px);
      overflow: hidden;

      // .content-container {
      //   height: 100%;
      //   overflow-y: scroll;
      //   -ms-overflow-style: none;

      //   &::-webkit-scrollbar {
      //     display: none;
      //   }

      //   flex: 1;

      //   >.container:last-child {
      //     padding-bottom: 4.5rem;
      //   }
      // }
    }
  }
}

@media (min-width: 992px) {
  .layout-nav-side {
    max-width: 373px;
    padding: 1.5rem;
  }
}
