@import '../../globalstyles/variables';

.defaultButton, .primaryButton, .tertiaryButton {
    width: 100%;
    padding: 0.750rem 1rem;
}

.customModal {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    border: 0;
}

.tertiaryButton {
    margin-bottom: 1rem;
}

.modalFooterWithTwoButtons, .modalFooterWithThreeButtons {
    display: block;
    > :not(:first-child), > :not(:last-child) {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 576px) {
    .defaultButton, .primaryButton, .tertiaryButton {
        width: unset;
        padding: 0.750rem 1.25rem;
    }
    .defaultButton {
        margin-left: auto !important;
        margin-right: 0.5rem !important;
    }
    .tertiaryButton {
        margin-bottom: 0;
    }
    .modalFooterWithTwoButtons, .modalFooterWithThreeButtons {
        display: inline-flex;
    }
}

.modalDialog {
    max-width: 536px;
}

.termsAndConditionsBody {
    margin-top: 2rem;
    h2, h3 {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.5;
        letter-spacing: normal;
        text-align: left;
        color: #333333;
        margin-bottom: 1.5rem;
        @media (min-width: 768px) {
            font-size: 1.5rem;
        }
    }
    h3 {
        margin-top: 2rem;
    }
}

.criticalErrorSummary {
    margin-bottom: 2.5rem !important;
    border: 2px solid $red !important;
    background: $defaultColor;
    padding: 1rem;
}

.criticalErrorSummary p {
    font-size: 1.125rem;
    font-weight: bold;
    line-height: 1.22;
    padding: 0.5rem;
}

.errorModal {
    min-width: 50%;
}

.errorModalHeader {
    display: block;
    margin-bottom: 2rem;
    padding: 0% 5% 0 5%;
    border: 0;

    h1 {
        font-size: 2.5rem;
        font-weight: 700;
        color: $matteText;
        padding-top: 2rem;
    }

    hr {
        margin-top: 0.25rem;
    }

    button {
        position: absolute;
        top: 0;
        right: 12px;
        font-size: 2.5rem;
        font-weight: 400;
        padding: 0;
    }
}

.errorBody {
    padding: 0% 5% 0 5%;
    > div {
        margin-bottom: 2rem;
    }
}

.eotRegistration {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.withdrawRegistration {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.rtvRegistration {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.spinnerBox {
    min-height: 2rem;
}

.revertToDraft {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.rtvRegistrationBody {
    margin-bottom: 1rem;

    h2 {
        font-weight: 700;
        font-size: 1.750rem;
        padding-bottom: 1rem;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    hr {
        border: 0;
        border-top: 0.125rem solid $secondaryInteractive;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    p, ul, blockquote, span {
        margin-bottom: 0.75rem;
    }

    a {
        color: $link;

        &:hover {
            color: $linkHover;
        }

        &:visited {
            color: $linkVisited;
        }
    }

    @media (min-width: 768px) {
        h2 {
            font-weight: 700;
            font-size: 2rem;
            padding: 1rem 0;
        }

        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }
}

.withdrawRegistrationBody {
    margin-bottom: 1rem;

    h2 {
        font-weight: 700;
        font-size: 1.750rem;
        padding-bottom: 1rem;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    hr {
        border: 0;
        border-top: 0.125rem solid $secondaryInteractive;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    p, ul, blockquote, span {
        margin-bottom: 0.75rem;
    }

    a {
        color: $link;

        &:hover {
            color: $linkHover;
        }

        &:visited {
            color: $linkVisited;
        }
    }

    @media (min-width: 768px) {
        h2 {
            font-weight: 700;
            font-size: 2rem;
            padding: 1rem 0;
        }

        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }
}

.eotRegistrationBody {
    margin-bottom: 1rem;
    h2 {
        font-weight: 700;
        font-size: 1.750rem;
        padding-bottom: 1rem;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    hr {
        border: 0;
        border-top: 0.125rem solid $secondaryInteractive;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    strong {
        text-decoration: none;
    }
    p, ul, blockquote, span {
        margin-bottom: 0.75rem;
    }

    a {
        color: $link;
        &:hover {
            color: $linkHover;
        }
        &:visited {
            color: $linkVisited;
        }
    }
    @media (min-width: 768px) {
        h2 {
            font-weight: 700;
            font-size: 2rem;
            padding: 1rem 0;
        }

        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }
}

.eotRegistrationButtonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 2rem 0 0 0;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 576px) {
        display: block;
        text-align: right;

        button {
            margin-left: 24px;
            margin-bottom: 1rem;
        }
    }
    .modalCancel {
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

.rtvRegistrationButtonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 2rem 0 0 0;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 576px) {
        display: block;
        text-align: right;

        button {
            margin-left: 40px;
            margin-bottom: 1rem;
        }
    }

    .modalCancel {
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

.withdrawRegistrationButtonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 2rem 0 0 0;
    margin: 2rem 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 576px) {
        display: block;
        text-align: right;

        button {
            margin-left: 40px;
            margin-bottom: 1rem;
        }
    }

    .modalCancel {
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

.submitWizardModal {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.submitWizardModalBody {
    margin-bottom: 0;
    padding-top: 2rem;

    h2 {
        font-weight: 700;
        font-size: 1.750rem;
        padding-bottom: 1rem;
    }

    h2 svg {
        padding-right: 10px;
        padding-bottom: 5px;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    hr {
        border: 0;
        border-top: 0.125rem solid $secondaryInteractive;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    a {
        color: $link;

        &:hover {
            color: $linkHover;
        }

        &:visited {
            color: $linkVisited;
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 1rem;
        padding-top: 1rem;

        h2 {
            font-weight: 700;
            font-size: 2rem;
            padding: 1rem 0;
        }

        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }
}

.submitWizardModalButtonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 2rem 0 0 0;
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 576px) {
        display: block;
        text-align: right;

        button {
            margin-left: 40px;
            margin-bottom: 1rem;
        }
    }

    .modalCancel {
        padding: 1rem;
        margin-bottom: 1rem;
    }
}

.rndBounceBack {
    color: $headerTextColor;
    background-color: $white;
    border: 0;
}

.rndBounceBackBody {
    margin-bottom: 0;
    padding-top: 2rem;

    h2 {
        font-weight: 700;
        font-size: 1.750rem;
        padding-bottom: 1rem;
    }

    h2 svg {
        padding-right: 10px;
        padding-bottom: 5px;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    hr {
        border: 0;
        border-top: 0.125rem solid $secondaryInteractive;
        margin: 0 0 2rem 0;
        text-align: left;
    }

    a {
        color: $link;

        &:hover {
            color: $linkHover;
        }

        &:visited {
            color: $linkVisited;
        }
    }

    @media (min-width: 768px) {
        margin-bottom: 1rem;
        padding-top: 1rem;

        h2 {
            font-weight: 700;
            font-size: 2rem;
            padding: 1rem 0;
        }

        h3 {
            margin-bottom: 1.5rem;
            font-size: 1.25rem;
            line-height: 1.875rem;
        }
    }
}

.rndBounceBackButtonGroup {
    border-top: 2px solid $buttonGroupBorder;
    padding: 2rem 0 0 0;
    margin: 2rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (min-width: 576px) {
        display: block;
        text-align: right;

        button {
            margin-left: 40px;
            margin-bottom: 1rem;
        }
    }

    .modalCancel {
        padding: 1rem;
        margin-bottom: 1rem;
    }
}
