@import 'variables';

.nav-link {
    font-weight: 600;
    color: $matteText;
    &.disabled {
        font-weight: 400;
        color: $disabledLabelColor;
        cursor: no-drop;
    }
}

a.nav-link {
    color: $matteText;
    &:hover {
        color: $linkHover;
    }
}
