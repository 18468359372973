@import 'variables';

label {
    font-size:              $questionFontSize;
    font-weight:            $questionFontWeight;
    line-height:            $questionLineHeight;
    text-align:             left;
    color:                  $text;
    margin-bottom:          $questionMarginBottom;
}

.rdtiQuestion {
    font-size:              $questionFontSize;
    font-weight:            $questionFontWeight;
    line-height:            $questionLineHeight;
    text-align:             left;
    color:                  $text;
    margin-bottom:          $questionMarginBottom;
}

.rdtiSubQuestion {
    font-size:              0.875rem;
    font-weight:            $questionFontWeight;
    line-height:            $questionLineHeight;
    text-align:             left;
    color:                  $text;
    margin-bottom:          0.375rem;
}

.labelSubHeading {
    font-size: 1.5rem;
    font-weight: $questionFontWeight;
    line-height: $questionLineHeight;
    color: $matteText;
    margin: 1.5rem 0;
}

legend {
    font-size:              $questionFontSize;
    font-weight:            $questionFontWeight;
    line-height:            $questionLineHeight;
    text-align:             left;
    color:                  $text;
    margin-bottom:          $questionMarginBottom;
}

.form-group {
    margin-bottom: 0;
}

.form-control {
    border:  solid 0.125rem $contrastGrey;
    border-radius: 0.125rem;
    height: 3.5rem;
    background-color: $defaultColor;
    color: $text;
    &::placeholder {
        color: $greyText;
        font-weight: 400;
        font-style: italic;
    }
    &:focus {
        box-shadow: none;
        border-color: $contrastGrey;
        outline: 2px solid $focusColor;
        outline-offset: 2px;
    }
}

.form-control:disabled,
.form-control[readonly] {
    background-color: $disabledBackgroundColor;
    border: solid 0.125rem $disabledBorderColor;
}

.form-check-input,
.form-check-inline .form-check-input {
    position: absolute;
    margin-top: 28px;
    margin-left: 28px;
    z-index: -1;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: $greenInteractive;
    padding-right: calc(1.5em + .75rem);
    background-position: 97%;
}

.form-check-input:disabled {
    +label {
        color: $text;
        opacity: 0.5;

        &:hover {
            border-color: $contrastGrey;
            cursor: not-allowed;

            &:before {
                box-shadow: 0 0 0 2px $contrastGrey;
            }
        }

        &:before {
            box-shadow: 0 0 0 2px $contrastGrey;
        }
    }
}

.form-check-label p {
    margin-bottom: 0px;

    br {
        display: block;
        margin-bottom: 10px;
        line-height: 150%;
        content: " ";
    }
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $greenInteractive;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated .form-control:valid,
.form-control.is-valid {
    border-color: $greenInteractive;
    padding: 1rem;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
    border-color: $greenInteractive;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip,
.form-control.is-valid~.valid-feedback,
.form-control.is-valid~.valid-tooltip {
    display: block;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding: 1rem;
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
    border-color: $greenInteractive;
    padding: 1rem;
    background: none;
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
    border-color: $greenInteractive;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-select:valid~.valid-feedback,
.was-validated .form-select:valid~.valid-tooltip,
.form-select.is-valid~.valid-feedback,
.form-select.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip,
.form-control-file.is-valid~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: $greenInteractive;
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: $greenInteractive;
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
    color: $greenInteractive;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip,
.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip {
    display: block;
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em;
}

.was-validated .form-file-input:valid~.form-file-label,
.form-file-input.is-valid~.form-file-label {
    border-color: $greenInteractive;
}

.was-validated .form-file-input:valid~.valid-feedback,
.was-validated .form-file-input:valid~.valid-tooltip,
.form-file-input.is-valid~.valid-feedback,
.form-file-input.is-valid~.valid-tooltip {
    display: block;
}

.was-validated .form-file-input:valid:focus~.form-file-label,
.form-file-input.is-valid:focus~.form-file-label {
    border-color: $greenInteractive;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin: 0 0 0.25rem 0;
    font-size: 0.875rem;
    color: $red;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 0.25rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    border-color: $red;
    padding: 1rem;
    background-image: none;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
    border-color: $red;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip,
.form-control.is-invalid~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
    border-color: $red;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: none;
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
    border-color: $red;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-select:invalid~.invalid-feedback,
.was-validated .form-select:invalid~.invalid-tooltip,
.form-select.is-invalid~.invalid-feedback,
.form-select.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip,
.form-control-file.is-invalid~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: $red;
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
    background-color: #e4606d;
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
    color: $red;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip,
.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip {
    display: block;
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em;
}

.was-validated .form-file-input:invalid~.form-file-label,
.form-file-input.is-invalid~.form-file-label {
    border-color: $red;
}

.was-validated .form-file-input:invalid~.invalid-feedback,
.was-validated .form-file-input:invalid~.invalid-tooltip,
.form-file-input.is-invalid~.invalid-feedback,
.form-file-input.is-invalid~.invalid-tooltip {
    display: block;
}

.was-validated .form-file-input:invalid:focus~.form-file-label,
.form-file-input.is-invalid:focus~.form-file-label {
    border-color: $red;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

  .is-invalid input {
    border: solid 0.125rem $red;
  }

// This bootstrap override affects the 'append' and 'prepend' areas on the currency input fields.
.input-group-text {
    font-weight: 600;
    color: $matteText;
    background-color: $inputPrependGrey;
    border: 2px solid $contrastGrey;
}

.input-group > .input-group-prepend > .input-group-text {
    border-right: 0;
}

.input-group > .input-group-append > .input-group-text {
    border-left: 0;
}

.bg-UploadProgress {
    background-color: #4fbfff !important;
}

input:disabled {
    cursor: not-allowed;
}
