@import '../../globalstyles/variables';

.footerTop {
  width: 100%;
}

.footerTopContent {
  padding-bottom: 4rem;
  padding-top: 1.5rem;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0 0 1rem 0;
    margin-top: 2.5rem;
    color: $defaultColor;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  li {
    background: url('/images/icon-whiteChev.svg') no-repeat left center;
    background-size: 16px 16px;
    padding-left: 28px;
    margin-bottom: 14px;
    line-height: 1.5rem;
    display: flex;
    a {
      color: $defaultColor;
      text-decoration: underline;
      &:hover {
        font-weight: 600;
      }
      &:focus {
        outline: 2px solid $focusColorOnDarkBackground;
        outline-offset: 2px;
      }
    }
  }
}

.footerBottomContent {
  max-width: 1140px;
  margin: 0 auto;
  img {
    max-width: 21rem;
  }
  a {
    &:focus {
      outline: 2px solid $focusColorOnDarkBackground;
      outline-offset: 2px;
    }
  }
}

.footerBottom {
  background-color: $primaryActive;
  width: 100%;
  padding-top: 2rem;
}

.acknowledgement {
  padding-top: 3rem;
  padding-bottom: 1rem;
  p {
    padding-bottom: 2rem;
    line-height: 1.75rem;
  }
}

.footerButton {
  text-decoration: underline;
  color: $defaultColor;
  font-weight: 400;
  text-align: left;
  &:hover {
    font-weight: 600;
    color: $defaultColor;
    text-decoration: underline;
  }
}

.footerLink {
  color: white;
}
