@charset "UTF-8";
.pageWrapper {
  margin: 0 auto;
  max-width: 1140px;
  padding: 3.5rem 0;
}

.confirmArea {
  margin-bottom: 2em !important;
}

.confirmWrap {
  flex-direction: column;
}

.confirmRef {
  font-weight: bold;
}

.wot {
  border-bottom: 2px solid #C7C7C7;
  height: 0;
  margin-bottom: 1.5rem;
}

.minorHeading {
  font-size: 2.25rem;
}

.wotWrapper {
  margin-top: 3rem;
}

.wotNextList {
  list-style: none !important;
  padding-left: 0;
}

.wotNextList li {
  line-height: 1.2;
  padding-bottom: 1rem;
}
.wotNextList li:before {
  content: "🡲";
  color: #E5B13D;
  margin-right: 16px;
  font-weight: bolder;
}

a {
  color: #0063E8;
}