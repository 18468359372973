@import '../../globalstyles/variables';

.wizardSider {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem;
    background-color: white;
    border-bottom: #ebebeb solid 0.125rem;

    @media only screen and (min-width: 768px) {
        padding-right: 2.625rem;
        display: block;
        margin: 0;
        background-color: transparent;
        border-bottom: none;
    }
}

.wizardContent {
    min-height: 26rem;
    margin-bottom: 3rem;
}

.overlay {
    z-index: 998;
}

.wizardSubHeader {
    background-color: $primaryInteractive;
    color: #FFFFFF;
}

.wizardFormHeader {
    font-size: 1rem;
    text-transform: uppercase;
}

.wizardFlexWrapper {
    display: flex;
    align-items: stretch;
    flex-direction: row;
    
    @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
    }
} 

.wizardSiderFlex {
    flex-grow: 1;
}

.wizardBodyFlex {
    flex-grow: 2;

    @media (max-width: 767.98px) {
        flex-grow: 1;
    }
}
