@import '../../../globalstyles/variables';

.expandableHelpButton {
  button {
    font-size: 0.875rem;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: $primaryInteractive;
    cursor: pointer;
    text-decoration: underline;
    border: none;
    padding: 0;
    background-color: transparent;
    padding-left: 18px;
    text-indent: -20px;
    margin-bottom: 20px;
    &:hover {
      text-decoration: none;
      font-weight: 600;
      box-shadow: inset 0 -20px 0 $linkHoverBackground;
      transition-delay: 0s, 0s;
      transition-duration: 0.2s, 0.2s;
      transition-property: box-shadow, -webkit-box-shadow;
      transition-timing-function: ease, ease;
    }
    &:focus {
      outline: 2px solid $focusColor;
      outline-offset: 2px;
    }
  }
}

.helpContentText {
  font-size: 0.875rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.375;
  letter-spacing: normal;
  text-align: left;
  color: $helpText;
  padding-left: 4%;
  padding-top: 0.5rem;
  margin-left: 0.375rem;
  border-left: solid $lighterGrey;

  p,
  ul,
  blockquote,
  span {
    margin-bottom: 0.75rem;
  }

  a {
    color: $link;
    &:hover {
      color: $linkHover;
    }
    &:visited {
      color: $linkVisited;
    }
  }
}

.nestedListNumberLatin {
  ol {
    counter-reset: level1;
    padding-inline-start: 0px;
  }

  ol li {
    list-style-type: none;
  }

  ol li p {
    display: inline;
  }

  ol li::before {
    content: '(' counter(level1, decimal) ') ';
    counter-increment: level1;
  }

  ol ol {
    counter-reset: level2;
  }
  ol ol li {
    padding-inline-start: 40px;
    list-style-type: none;
  }

  ol ol li::before {
    content: '(' counter(level2, lower-latin) ') ';
    counter-increment: level2;
  }
}

.nestedListLatinRoman {
  ol {
    counter-reset: level1;
    padding-inline-start: 0px;
  }

  ol li {
    list-style-type: none;
  }

  ol li p::before {
    content: '(' counter(level1, lower-latin) ') ';
    counter-increment: level1;
  }

  ol ol {
    counter-reset: level2;
  }
  ol ol li {
    padding-inline-start: 40px;
    list-style-type: none;
  }

  ol ol li::before {
    content: '(' counter(level2, lower-roman) ') ';
    counter-increment: level2;
  }
}

.contentFieldWrapper {
  margin-bottom: 2.5rem;
}

@media (min-width: 576px) {
  .contentFieldWrapper {
    margin-bottom: 3rem;
  }
}

.caretRight {
  float: left;
  padding-right: 20px;
  height: 14px;
  width: 12px;
}

.caretRight svg {
  height: 14px;
  width: 12px;
}

.caretDown {
  float: left;
  padding-right: 20px;
  height: 14px;
  width: 12px;
}

.caretDown svg {
  height: 14px;
  width: 12px;
}

.alertContentText {
  font-size: 1rem;
  line-height: 1.3;
  a {
    color: $link;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      color: $linkHover;
      text-decoration: underline;
    }
  }
}

.helpContentText {
  a {
    color: $link;
    font-weight: 500;
    text-decoration: underline;
    &:hover {
      color: $linkHover;
      text-decoration: underline;
    }
  }
}
