@import 'variables';

.custom-select {
    display: block;
    border:  solid 0.125rem $contrastGrey;
    color: $text;
    line-height: 1.375;    
}

.custom-select.was-validated.form-control:invalid, .custom-select.form-control.is-invalid {
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") $defaultColor no-repeat right 0.75rem center/8px 10px; 
}
