@import '../../globalstyles/variables';

.activeIndicator {
    border-left: 3px solid;
    margin-left: -3px;

    @media only screen and (min-width: 768px) {
        // border-left: 0.1875rem;
        // border-left-style: solid;
        // border-bottom: none;
        margin-left: 0;
    }
}

.activeInProgress {
    border-left-color: $navigationInProgress;
    background-color: $defaultColor;

    @media only screen and (min-width: 768px) {
        border-left-color: $navigationInProgress;
    }
}

.activeComplete {
    border-left-color: $greenInteractive;
    background-color: $defaultColor;

    @media only screen and (min-width: 768px) {
        border-left-color: $greenInteractive;
    }
}

.activeNotStarted {
    border-left-color: $bluePrimary;
    background-color: $defaultColor;

    @media only screen and (min-width: 768px) {
        border-left-color: $bluePrimary;
    }
}

.inactiveIndicator {
    border: none;

    @media only screen and (min-width: 768px) {
        border-left: 0.1875rem;
        border-left-style: solid;
        border-left-color: transparent;
    }
}

.activeMeta {
    color: $matteText;
    border-style: none;
    font-weight: 800;
    font-size: 0.875rem;
}

.inactiveMeta {
    border-style: none;
    font-size: 0.875rem;
    color: $disabledText;
    // &:disabled {
    //     color: $disabledText;
    // }
}

.progressContainer {
    display: flex;
    justify-content: left;
    line-height: 2rem;
    @media only screen and (min-width: 768px) {
        line-height: 3.25rem;
    }
}

.progressIcon {
    padding-right: 2rem;
    height: 1.25rem;
    width: 1.25rem;
}

.progressIcon svg {
    height: 1.5rem;
    width: 1.25rem;
}

.activeSectionIcon {
    padding-right: 2rem;
    height: 1rem;
    width: 1.25rem;
}

.activeSectionIcon svg {
    height: 1rem;
    width: 1.25rem;
}

.colorNotStarted svg {
    fill: $bluePrimary;
}

.colorInProgress svg {
    fill: $navigationInProgress;
}

.colorComplete svg {
    fill: $greenInteractive;
}
