// Table or grid item styling

.yearPanel {
    margin-bottom: 1rem;
    border-radius: 0 !important;
    border: 0 !important;
}

.cardHeader {
    min-height: 70px;
    background-color: $groupHeaderBackground;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0;
    
}

.pullRight {
    float: right;
}

.cardBody {
    padding: 0;
}

.cardHeaderRefNumber {
    margin-right: 24px;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;

}

.cardHeaderBtn {
    font-size: 20px;
    color: $text;
    text-decoration: none;
    font-weight: bold;
    line-height: 24px;
    width:100%;
    text-align: left;
    padding: 1.5rem;
    position: relative;
    z-index: 10;
    &:hover {
        cursor: pointer;
        background-color: #DEE2EA;
    }
}

div[class^="dataGrid_dataGridExpand"] {
    display: none;
}
