@import 'variables';

.alert {
    background-color: white;
    color: $matteText;
    border-radius: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-left: solid 5px blue;
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: 1.250rem 1rem;
    background-size: 26px 26px;
    min-height: 3.75rem;
    p {
        margin: 0;
    }
}
